import React from "react";
import styled from 'styled-components';

const FooterStyles = styled.footer`
    background: rgb(146,133,81);
    background: linear-gradient(0deg, rgba(146,133,81,1) 0%, rgba(146,133,81,0.4) 76%, rgba(146,133,81,1) 100%), linear-gradient(white, white);;
    height: 35px;
    position: sticky;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    z-index: 1;
`;

export default function Footer() {
  return <FooterStyles>
    <p>&copy; Transnet Multilingual Services {new Date().getFullYear()}</p>
  </FooterStyles>
}
