import { createGlobalStyle } from 'styled-components';
import regular from '../assets/fonts/Roboto-Regular.ttf';
import medium from '../assets/fonts/Roboto-Medium.ttf';
import bold from '../assets/fonts/Roboto-Bold.ttf';

const Typography = createGlobalStyle`
    @font-face {
        font-family: Roboto-Regular;
        src: url(${regular});
    }

    @font-face {
        font-family: Roboto-Medium;
        src: url(${medium});
    }

    @font-face {
        font-family: Roboto-Bold;
        src: url(${bold});
    }

    html {
        font-family: Roboto-Regular, Arial, Helvetica, sans-serif;
        line-height: 1.25rem;
    }

    h1 {
        line-height: 2.25rem;
        font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
    }

    h2 {
        line-height: 1.5rem;
        font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
    }

    h3 {
        font-family: Roboto-Bold, Arial, Helvetica, sans-serif;
    }
`;

export default Typography;